






















































































































import { Component, Watch } from 'vue-property-decorator';

import BaseComponent from '../components/BaseComponent';
import ErrorComponent from '../components/ErrorComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import EmploymentComponent from '../components/EmploymentComponent.vue';
import EmploymentHelper from '../components/EmploymentHelper';

import { searchIndigenousEmployments } from '../store/rest.service';
import { ResourceSearchResult, SortColumnMapping, SearchIndigenousEmployments  } from '../store/models';
import { EmptySearchIndigenousEmployments } from '../store/models-empty';

import { handleError } from '../lib/shared';
import * as _ from 'lodash';
import { getIconUrl } from '@/lib/images';
import * as features from '../lib/feature-constants';

@Component({
  components: {
    SpinnerComponent,
    DialogComponent,
    DatePickerComponent,
    ErrorComponent,
    EmploymentComponent,
  },
})
export default class Employments extends BaseComponent {

  protected baseRoute: string = '/employment';
  protected feature: string = features.employmentMenu;

  private helper: EmploymentHelper = new EmploymentHelper();
  private helperLoading: boolean = true;

  private employments: ResourceSearchResult|null = null;
  private employmentsLoading: boolean = false;
  private projectId: string = '';
  private editedId: string = '';
  private bEditIndigenousEmployment: boolean = false;

  private arrErrors: Error[] = [];
  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;

  private pagination: any = { sortBy: 'employment.employmentDate', descending: true, page: 1, rowsPerPage: 10 };
  private rowsPerPage: number[] = [10, 25, 100];
  private bUpdateClicked: boolean = false;
  private updateCriteria: number = 0;

  private searchCriteria: SearchIndigenousEmployments = _.cloneDeep(EmptySearchIndigenousEmployments);

  private sortMappings: SortColumnMapping[] = [];

  private headers = [
    { text: 'Work Day', align: 'left', sortable: true, value: 'payment.effectiveDate', class: 'grid-header' },
    { text: 'Total Wages ($)', align: 'right', sortable: true, value: 'payment.amount', class: 'grid-header' },
    { text: 'Occupation', align: 'left', sortable: true, value: 'employment.occupation', class: 'grid-header' },
    { text: 'Is Indigenous?', align: 'center', sortable: true, value: 'diversity.isIndigenous', class: 'grid-header' },
    { text: 'Is Diverse?', align: 'center', sortable: true, value: 'diversity.isDiverse', class: 'grid-header' },
  ];

  protected changeCurrentOrg() {
    this.helper.getProjects(this.currentOrg, true);
    this.getEmployments();
  }

  protected mounted() {
    this.mount();
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
      if (!this.bInitialLoadWatch) {
        this.updateCharts();
      }
    }
    this.bInitialLoad = false;
    this.bInitialLoadWatch = false;
  }

  private mount() {
    this.projectId = this.$route.params.id;
    this.searchCriteria.project = this.projectId;
    this.helper.populateDataLists(this.currentOrg);
    this.getEmployments();
  }

  private updateCharts() {
    this.bUpdateClicked = true;
    this.updateCriteria++;
    this.getEmployments();
  }

  private getEmployments() {
    this.employmentsLoading = true;
    searchIndigenousEmployments(this.mapSearchParams(this.pagination, '', this.sortMappings), this.searchCriteria)
    .then((response) => {
      this.employments = response;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.employmentsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private getTickIcon(): string {
    return getIconUrl('icon_tick.png');
  }

  private editEmployment(id) {
    this.editedId = id;
    this.bEditIndigenousEmployment = true;
  }

  private closeEditIndigenousEmployment() {
    this.editedId = '';
    this.bEditIndigenousEmployment = false;
  }

  private saveEmployment() {
    this.updateCharts();
  }

  private navImport() {
    this.changeRoute('/projects/id/' + this.projectId + '/employment/import');
  }

  private getKey(label: string) {
    return this.projectId + this.updateCriteria + label;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }
}
