

















































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';
import FormattedTextBoxComponent from '../components/FormattedTextBoxComponent.vue';
import { getEmployment, createEmployment, updateEmployment } from '../store/rest.service';
import { ResourceSearchResult, GeneralSearchCriteria, Employment } from '../store/models';
import { EmptyGeneralSearchCriteria, EmptyEmployment } from '../store/models-empty';
import EmploymentHelper from '../components/EmploymentHelper';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    ValidationSummaryComponent,
    DatePickerComponent,
    SpinnerComponent,
    FormattedTextBoxComponent,
  },
})
export default class IndigenousEmployments extends BaseComponent {

  @Prop() private indigenousEmploymentId: any;
  @Prop() private projectId: any;
  @Prop() private defaultProjectId: any;

  private helper: EmploymentHelper = new EmploymentHelper();
  private helperLoading: boolean = true;

  private indigenousEmploymentLoading: boolean = false;
  private indigenousEmploymentSaving: boolean = false;
  private indigenousEmploymentSaveResponse: any;
  private indigenousEmploymentError: Error|null = null;

  private employmentErrors: Error[] = [];

  private searchCriteria: GeneralSearchCriteria =  _.cloneDeep(EmptyGeneralSearchCriteria);

  private editedId: string = '';
  private editedWorkDay: string = '';
  private editedProjectId: string = '';
  private editedProjectName: string = '';

  private editedItem: Employment = _.cloneDeep(EmptyEmployment);
  private editedItems: Employment[] = [];
  private itemsToSaveCount: number = 0;
  private updateValidation: number = 0;

  protected mounted() {
    this.editedItems = [];
    if (this.indigenousEmploymentId !== '') {
      this.editIndigenousEmployment(this.indigenousEmploymentId);
    } else {
      this.newIndigenousEmployment();
    }
  }

  @Watch('helper.isLoading')
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
  }

  private deletePosition(index: number) {
    this.editedItems.splice(index, 1);
  }

  private isNewPosition(index: number) {
    return !(this.editedItems[index].common);
  }

  private isLoading(): boolean {
    return this.indigenousEmploymentLoading
    || this.helperLoading;
  }

  private formTitle(): string {
    return this.editedId === '' ? 'Add New Employment' : 'Edit Employment';
  }

  private addPosition() {
    const newEmployment = _.cloneDeep(EmptyEmployment);
    this.editedItems.push(newEmployment);
  }

  private updateEditedIndigenousEmployment(id: string) {
    this.editedId = id;
    this.getEmployment(id);
  }

  private editIndigenousEmployment(id: string) {
    this.updateEditedIndigenousEmployment(id);
    this.helper.populateDataLists(this.currentOrg);
  }

  private newIndigenousEmployment() {
    this.helper.populateDataLists(this.currentOrg);
    this.editedId = '';
    this.editedItem = _.cloneDeep(EmptyEmployment);
    this.editedProjectName = this.defaultProjectId;
    this.editedItem.payment.hasProject = this.defaultProjectId;
    this.editedItem.payment.effectiveDate = this.editedWorkDay;
    this.editedItems.push(this.editedItem);
  }

  private close() {
    if (this.editedId !== '') {
      this.updateEditedIndigenousEmployment(this.editedId);
    }
    this.editedItems = [];
    this.editedItem = _.cloneDeep(EmptyEmployment);
    this.$emit('close');
  }

  private save() {
    if (!this.indigenousEmploymentSaving) {
      if ((this.$refs.form as any).validate()) {
        this.updateValidation++;
        this.itemsToSaveCount = this.editedItems.length;
        for (const item of this.editedItems) {
          // Update the Project, Work Day, Submitter and Employer.
          item.payment.effectiveDate = this.editedWorkDay;
          item.payment.hasProject = this.editedProjectName;
          item.payment.hasPayer = this.currentOrg;
          item.payment.hasSubmitter = this.currentUser.userinfo.identifier;
          /*
          if (item.employment.hourlyRate === 0) {
            delete item.employment.hourlyRate;
          }
          */
          const id = item.common ? item.common.identifier ? item.common.identifier : '' : '';
          id !== '' ? this.updateEmployment(id, item) : this.createEmployment(item);
        }
      } else {
        this.updateValidation++;
      }
    }
  }

  private getEmployment(id: string) {
    this.indigenousEmploymentLoading = true;
    getEmployment(id, false).then((response) => {
      this.editedItem = response;
      this.editedItems.push(this.editedItem);
      this.editedProjectName = this.editedItem.payment.hasProject as string;
      this.editedWorkDay = this.editedItem.payment.effectiveDate as string;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.indigenousEmploymentLoading = false;
    });
  }

  private createEmployment(newIndigenousEmployment: Employment) {
    this.indigenousEmploymentSaving = true;
    createEmployment(newIndigenousEmployment).then((response) => {
      this.indigenousEmploymentSaveResponse = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.indigenousEmploymentSaving = true;
      this.itemsToSaveCount--;
      if (this.itemsToSaveCount === 0) {
        this.indigenousEmploymentSaving = false;
        this.$emit('save');
        this.close();
      }
    });
  }

  private updateEmployment(id: string, editedIndigenousEmployment: Employment) {
    this.indigenousEmploymentSaving = true;
    updateEmployment(id, this.removeCommonProperties(editedIndigenousEmployment)).then((response) => {
      this.indigenousEmploymentSaveResponse = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.indigenousEmploymentSaving = true;
      this.itemsToSaveCount--;
      if (this.itemsToSaveCount === 0) {
        this.indigenousEmploymentSaving = false;
        this.$emit('save');
        this.close();
      }
    });
  }
}
